<template>
  <div></div>
</template>

<script type="text/javascript">
export default {
  name: 'ICORMain',
  data () {
    return {
    }
  }
}
</script>

<style scoped>
</style>
